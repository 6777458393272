.Fi-checkout-container {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  @media (min-width: 768px) {
    .Fi-checkout-container {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  
  .Fi-billing-details,
  .Fi-order-summary,
  .Fi-payment-section {
    background-color: #ffffff;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .Fi-billing-details {
    flex: 2;
    margin-right: 20px;
  }
  
  .Fi-order-summary,
  .Fi-payment-section {
    flex: 1;
  }
  
  .Fi-form-group {
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 30px;
}
  
  .Fi-form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 14px;
    color: #333;
  }
  
  .Fi-form-group input[type="text"],
  .Fi-form-group input[type="email"],
  .Fi-form-group input[type="tel"],
  .Fi-form-group select {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .Fi-form-group input[type="checkbox"] {
    margin-right: 10px;
  }
  
  .Fi-terms-and-conditions {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
  }
  
  .Fi-terms-and-conditions input[type="checkbox"] {
    margin-right: 10px;
  }
  
  .Fi-terms-and-conditions label {
    font-size: 14px;
    line-height: 1.5;
  }
  
  .Fi-payment-methods {
    margin-bottom: 20px;
  }
  
  .Fi-payment-methods div {
    margin-bottom: 15px;
  }
  
  .Fi-payment-methods label {
    margin-left: 8px;
  }
  
  .Fi-place-order-button {
    background-color: #e54206;
    color: #fff;
    border: none;
    padding: 12px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
  }
  
  .Fi-place-order-button:hover {
    background-color: #d87606;
  }
  
  .Fi-order-summary table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .Fi-order-summary th,
  .Fi-order-summary td {
    padding: 10px;
    text-align: left;
  }
  
  .Fi-order-summary th {
    border-bottom: 2px solid #ddd;
  }
  
  .Fi-total {
    font-weight: bold;
  }
  