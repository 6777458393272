input {
    font-family: "Poppins", sans-serif;
  }

  

.login-container {
    position: relative;
    width: 100%;
    background-color: #fff;
    min-height: 100vh;
    overflow: hidden;
  }

  .forms-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  
  .signin-signup {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 75%;
    width: 50%;
    transition: 1s 0.7s ease-in-out;
    display: grid;
    grid-template-columns: 1fr;
    z-index: 5;
  }

  .sign-up-form,.sign-in-form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0rem 5rem;
    transition: all 0.2s 0.7s;
    overflow: hidden;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }
  
  form.sign-up-form {
    opacity: 0;
    z-index: 1;
  }
  
  form.sign-in-form {
    z-index: 2;
  }

  .input-field {
    max-width: 380px;
    width: 100%;
    background-color: #f1f1f1a3;
    margin: 10px 0;
    height: 55px;
    border-radius: 5px;
    display: grid;
    grid-template-columns: 15% 85%;
    padding: 0 0.4rem;
    position: relative;
  }
  
  .input-field i {
    text-align: center;
    line-height: 55px;
    color: #acacac;
    transition: 0.5s;
    font-size: 1.1rem;
  }
  
  .input-field input {
    background: none;
    outline: none;
    border: none;
    line-height: 1;
    font-weight: 600;
    font-size: 1.1rem;
    color: #333;
  }
  
  .input-field input::placeholder {
    color: #aaa;
    font-weight: 500;
  }
  
  .social-text {
    padding: 0.7rem 0;
    font-size: 1rem;
  }
  
  .social-media {
    display: flex;
    justify-content: center;
  }
  
  .social-icon {
    height: 46px;
    width: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0.45rem;
    color: #333;
    border-radius: 50%;
    border: 1px solid #333;
    text-decoration: none;
    font-size: 1.1rem;
    transition: 0.3s;
  }
  
  .social-icon:hover {
    color: #dd7323;
    border-color: #dd7323;
  }

  .login-btn1{
    width: 150px;
    background-color: #dd7323;
    border: none;
    outline: none;
    height: 49px;
    border-radius: 49px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    margin: 10px 0;
    cursor: pointer;
    transition: 0.5s;
  }

  .login-btn1:hover {
    background-color: #dd7323;
  }

  .panels-container {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .login-container:before {
    content: "";
    position: absolute;
    height: 2000px;
    width: 2000px;
    top: -10%;
    right: 48%;
    transform: translateY(-50%);
    background-image: linear-gradient(-45deg, #dd7323 0%, #fec004 100%);
    transition: 1.8s ease-in-out;
    border-radius: 50%;
    z-index: 6;
  }


  .image {
    width: 100%;
    transition: transform 1.1s ease-in-out;
    transition-delay: 0.4s;
  }
  
  .panel {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
    text-align: center;
    z-index: 6;
    
  }
  
  .left-panel {
    pointer-events: all;
    padding: 3rem 17% 2rem 12%;
    
  }
  
  .right-panel {
    pointer-events: none;
    padding: 3rem 12% 2rem 17%;
  }
  
  .panel .content1 {
    color: white;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.6s;
  }
  
  .panel h3 {
    font-weight: 600;
    line-height: 1;
    font-size: 1.5rem;
  }
  
  .panel p {
    font-size: 0.95rem;
    padding: 0.7rem 0;
  }

  .login-btn.transparent {
    border-radius: 10px;
    color: white;
    margin: 0;
    background: none;
    border: 2px solid #fff;
    width: 130px;
    height: 41px;
    font-weight: 600;
    font-size: 0.8rem;
  }

  .shapier-name{
    display: flex;
    justify-content: start;
    align-items:center;
    /* margin-bottom: 1rem; */
    margin-right: 2rem;
  }

  .right-panel .image,
.right-panel .content1 {
  transform: translateX(800px);
}


.login-container.sign-up-mode:before {
    transform: translate(100%, -50%);
    right: 52%;
  }
  
  .login-container.sign-up-mode .left-panel .image,
  .login-container.sign-up-mode .left-panel .content1 {
    transform: translateX(-800px);
  }
  
  .login-container.sign-up-mode .signin-signup {
    left: 25%;
  }

  .login-container.sign-up-mode form.sign-up-form {
    opacity: 1;
    z-index: 2;
  }
  
  .login-container.sign-up-mode form.sign-in-form {
    opacity: 0;
    z-index: 1;
  }
  
  .login-container.sign-up-mode .right-panel .image,
  .login-container.sign-up-mode .right-panel .content1 {
    transform: translateX(0%);
  }
  
  .login-container.sign-up-mode .left-panel {
    pointer-events: none;
  }

  .login-container.sign-up-mode .right-panel {
    pointer-events: all;
  }
  

  @media (max-width: 870px) {
    .login-container {
      min-height: 800px;
      height: 100vh;
    }
    .signin-signup {
      width: 100%;
      top: 95%;
      transform: translate(-50%, -100%);
      transition: 1s 0.8s ease-in-out;
    }
  
    .signin-signup,
    .login-container.sign-up-mode .signin-signup {
      left: 50%;
    }
  
    .panels-container {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 2fr 1fr;
    }
  
    .panel {
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      padding: 2.5rem 8%;
      grid-column: 1 / 2;
    }
  
    .right-panel {
      grid-row: 3 / 4;
    }
  
    .left-panel {
      grid-row: 1 / 2;
    }
  
    .image {
      width: 200px;
      transition: transform 0.9s ease-in-out;
      transition-delay: 0.6s;
    }
  
    .panel .content1 {
      padding-right: 15%;
      transition: transform 0.9s ease-in-out;
      transition-delay: 0.8s;
    }
  
    .panel h3 {
      font-size: 1.2rem;
    }
  
    .panel p {
      font-size: 0.7rem;
      padding: 0.5rem 0;
    }
    
    .login-btn1.transparent {
        width: 110px;
        height: 35px;
        font-size: 0.7rem;
      }
    
      .login-container:before {
        width: 1500px;
        height: 1500px;
        transform: translateX(-50%);
        left: 30%;
        bottom: 68%;
        right: initial;
        top: initial;
        transition: 2s ease-in-out;
      }
    
      .login-container.sign-up-mode:before {
        transform: translate(-50%, 100%);
        bottom: 32%;
        right: initial;
      }
    
      .login-container.sign-up-mode .left-panel .image,
      .login-container.sign-up-mode .left-panel .content1 {
        transform: translateY(-300px);
      }
    
      .login-container.sign-up-mode .right-panel .image,
      .login-container.sign-up-mode .right-panel .content1 {
        transform: translateY(0px);
      }
    
      .right-panel .image,
      .right-panel .content1 {
        transform: translateY(300px);
      }
    
      .login-container.sign-up-mode .signin-signup {
        top: 5%;
        transform: translate(-50%, 0);
      }
    }
    
    @media (max-width: 570px) {
        form {
          padding: 0 1.5rem;
        }
      
        .image {
          display: none;
        }
        .panel .content1 {
          padding: 0.5rem 1rem;
        }
        .login-container {
          padding: 1.5rem;
        }
      
        .login-container:before {
          bottom: 72%;
          left: 50%;
        }
      
        .login-container.sign-up-mode:before {
          bottom: 28%;
          left: 50%;
        }
      }
  
  
  