/* ProductSection.css */




.PUJA-product-container {
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    margin: 20px 0;
  }
  
  .PUJA-product-section {
    display: flex;
    overflow-x: scroll;
    scroll-behavior: smooth;
    padding: 20px 0;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  
  .PUJA-product-section::-webkit-scrollbar {
    display: none;  /* Chrome, Safari and Opera */
  }
  
  .PUJA-product-card {
    background-color: white;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    width: 230px;
    height: 300px;
    margin-right: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .PUJA-product-card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .PUJA-product-image {
    width: 100%;
    height: 150px;
    border-radius: 5px;
    object-fit: cover;
  }
  
  .PUJA-product-name {
    font-size: 1.2em;
    color: #ff5722;
    margin: 5px 0;
  }

  .PUJA-product-name{
  /* white-space: wrap; */
  overflow: hidden;
  text-overflow: ellipsis;
}

  
  .PUJA-product-description {
    font-size: 0.9em;
    color: #666;
    margin: 5px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .PUJA-product-price {
    font-size: 1em;
    color: #333;
    margin: 5px 0;
  }
  
  .PUJA-add-to-cart-button {
    background-color: #ff5722;
    border: none;
    padding: 5px 10px;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    font-size: 0.9em;
    margin-top: 5px;
  }
  
  .PUJA-add-to-cart-button:hover {
    background-color: #e64a19;
  }
  
  .PUJA-scroll-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
  }
  
  .PUJA-scroll-button.left {
    left: 0;
  }
  
  .PUJA-scroll-button.right {
    right: 0;
  }
  
  @media (max-width: 600px) {
    .PUJA-product-card {
      width: 208px;
        margin-right: 10px;
        height: 315px;
    }

    .PUJA-product-name {
      color: #ff5722;
      font-size: 1em;
     
  }

    .PUJA-product-image {
      width: 100%;
      height: 130px;
      border-radius: 5px;
      object-fit: cover;
  }
  
    .PUJA-product-section {
      padding: 10px 0;
    }

    .PUJA-scroll-button.left {
      display: none;
    }
    
    .PUJA-scroll-button.right {
      display: none;
    }
  }
  