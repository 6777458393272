/* CheckoutPage.css */

.checkout-container {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h2 {
    color: #444;
  }
  
  .billing-info, .order-summary {
    background: #fff;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  form input {
    width: calc(100% - 20px);
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .order-summary .summary-header {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
    font-weight: bold;
  }
  
  .order-summary ul {
    list-style: none;
    padding: 0;
  }
  
  .order-summary li {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
  }
  
  .order-summary .item {
    flex: 1;
  }
  
  .order-summary .subtotal {
    flex: 1;
    text-align: right;
  }
  
  .total-price {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    font-weight: bold;
  }
  
  .place-order {
    width: 100%;
    padding: 15px;
    margin-top: 20px;
    background-color: #d05d0a;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .place-order:hover {
    background-color: #b42208;
  }
  
  @media (min-width: 768px) {
    .checkout-container {
      flex-direction: row;
    }
  
    .billing-info, .order-summary {
      flex: 1;
      margin: 10px;
    }
  }
  
  @media (max-width: 767px) {
    .checkout-container {
      flex-direction: column;
    }
  
    .billing-info, .order-summary {
      width: 88%;
      margin-bottom: 20px;
    }
  }
  