/* General Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Arial', sans-serif;
    background-color: #f7f7f7;
  }
  
  .wave-container,
  .wave-container-bottom {
    overflow: hidden;
    width: 100%;
    position: relative;
    z-index: -1;
  }
  
  .wave-path {
    animation: wave-animation 5s ease-in-out infinite alternate;
  }
  
  @keyframes wave-animation {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50px);
    }
  }
  
  .art-main {
    text-align: center;
    padding: 20px;
    background: linear-gradient(135deg, #fff8e1, #ffe082);
    margin-top: -5px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    animation: fadeIn 2s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .art-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
  }
  
  .art-text {
    font-size: 1.5rem;
    color: goldenrod;
    font-family: Edu AU VIC WA NT Hand;
    margin-bottom: 20px;
    line-height: 1.5;

    animation: slideIn 1.5s ease-out;
  }
  
  .logo-container {
    animation: bounceIn 2s ease-in-out;
  }
  
  @keyframes bounceIn {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    60% {
      transform: scale(1.2);
      opacity: 1;
    }
    100% {
      transform: scale(1);
    }
  }
  
  .logo-image {
    width: 200px;
    height: auto;
    transition: transform 0.3s ease;
  }
  
  .logo-image:hover {
    transform: scale(1.1);
  }
  
  .art2-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .art2-text {
    font-size: 3rem;
    color: #444;
    animation: pulse 2s infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
  