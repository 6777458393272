.admin-panel-container {
    display: flex;
    min-height: 100vh;
}

.content-container {
    flex-grow: 1;
    padding: 20px;
}

.page-title {
    font-size: 2em;
    margin-bottom: 20px;
}

.filter-container {
    margin-bottom: 20px;
    display: flex;
    gap: 10px;
}

.filter-container input {
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.filter-container button {
    padding: 10px 20px;
    font-size: 1em;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
}

.filter-container button:hover {
    background-color: #0056b3;
}

.table-container {
    overflow-x: auto;
}

.contact-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.contact-table th, .contact-table td {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: left;
}

.contact-table th {
    background-color: #f4f4f4;
}

.contact-table tr:nth-child(even) {
    background-color: #f9f9f9;
}
