.cart-heading {
    font-size: 2rem;
    margin: 20px 0;
    text-align: center;
    font-weight: bold;
}

.cart-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
}

.cart-items {
    width: 60%;
    margin-bottom: 20px;
}

.cart-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.cart-header span {
    font-weight: bold;
}

.cart-header-product {
    width: 40%;
}

.cart-header-price,
.cart-header-quantity,
.cart-header-subtotal {
    width: 20%;
    text-align: center;
}

.cart-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    background-color: #fff;
    flex-wrap: nowrap;
}

.item-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin-right: 20px;
}

.item-name {
    width: 40%;
    margin-bottom: 10px;
}

.item-price,
.item-total {
    width: 20%;
    text-align: center;
    margin-bottom: 10px;
}

.quantity-controls {
    display: flex;
    align-items: center;
}

.quantity-controls button {
    width: 30px;
    height: 30px;
    text-align: center;
    background: none;
    border: 1px solid #e0e0e0;
    cursor: pointer;
}

.item-quantity {
    width: 50px;
    text-align: center;
    border: 1px solid #e0e0e0;
    margin: 0 5px;
}

.delete-button {
    background: none;
    border: none;
    color: red;
    font-size: 1.5rem;
    cursor: pointer;
    margin-right: 10px;
}

.coupon-section {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.coupon-input {
    width: 60%;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
}

.apply-coupon,
.update-basket {
    padding: 10px 20px;
    border: none;
    background-color: #333;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
}

.order-summary {
    width: 35%;
    border: 1px solid #e0e0e0;
    padding: 20px;
    border-radius: 5px;
    background-color: #fff;
}

.order-summary h2 {
    margin-bottom: 20px;
    font-weight: bold;
}

.summary-detail {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.total-detail {
    font-weight: bold;
}

.proceed-checkout {
    width: 100%;
    padding: 15px;
    background-color: #333;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1rem;
    text-align: center;
}

/* Media Queries for Responsive Design */
@media (max-width: 1024px) {
    .cart-container {
        flex-direction: column;
        align-items: center;
    }

    .cart-items,
    .order-summary {
        width: 90%;
    }

    .cart-header {
        flex-direction: row;
        justify-content: space-around;
    }

    .cart-header span {
        width: auto;
        text-align: left;
    }

    .cart-item {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .item-image,
    .item-name,
    .item-price,
    .item-total,
    .quantity-controls {
        width: auto;
        text-align: left;
        margin: 10px 0;
    }

    .quantity-controls {
        justify-content: flex-start;
    }
}

@media (max-width: 768px) {
    .cart-header span {
        width: auto;
        text-align: center;
    }

    .cart-item {
        flex-direction: column;
        align-items: flex-start;
    }

    .item-image,
    .item-name,
    .item-price,
    .item-total,
    .quantity-controls {
        width: 100%;
        text-align: left;
        margin: 5px 0;
    }

    .quantity-controls {
        justify-content: space-between;
    }

    .quantity-controls button,
    .item-quantity {
        width: 30px;
        height: 30px;
    }

    .delete-button {
        margin-bottom: 10px;
    }

    .coupon-section {
        flex-direction: column;
        align-items: flex-start;
    }

    .coupon-input,
    .apply-coupon,
    .update-basket {
        width: 100%;
        margin-bottom: 10px;
    }

    .proceed-checkout {
        font-size: 0.9rem;
        padding: 10px;
    }
}

@media (max-width: 480px) {
    .cart-heading {
        font-size: 1.5rem;
    }

    .cart-container {
        padding: 10px;
    }

    .cart-header {
        display: none;
        flex-direction: column;
        align-items: flex-start;
    }

    .cart-header-product,
    .cart-header-price,
    .cart-header-quantity,
    .cart-header-subtotal {
        width: 100%;
        text-align: left;
        margin-bottom: 5px;
    }

    .cart-item {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
    }

    .item-image {
        width: 100%;
        height: auto;
        margin-bottom: 10px;
    }

    .item-name,
    .item-price,
    .item-total,
    .quantity-controls {
        width: 100%;
        text-align: left;
        margin-bottom: 10px;
    }

    .quantity-controls {
        width: 100%;
        justify-content: space-between;
    }

    .delete-button {
        margin-bottom: 10px;
    }

    .coupon-section {
        flex-direction: column;
        align-items: flex-start;
    }

    .coupon-input,
    .apply-coupon,
    .update-basket {
        width: 100%;
        margin-bottom: 10px;
    }

    .order-summary {
        width: 90%;
        margin-bottom: 20px;
    }

    .proceed-checkout {
        font-size: 0.9rem;
        padding: 10px;
    }
}
