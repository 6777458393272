/* Global Styles */
.container{
  background-color: white;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


.message-button-container {
  position: fixed;
  bottom: 100px; /* Adjust this to position it where you want */
  right: 20px;
  z-index: 1000;
}

.message-button {
  position: fixed;
  bottom: 110px;           
  right: 30px;
  width: 60px;
  height: 60px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgb(1, 1, 95);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 900;
}

.message-button img{
  width: 50px;
  height: 50px;
  /* object-fit: cover; */
}


/* Scroll container styles */
.message-options {
  position: fixed;
  right: 60px;
  bottom: 120px;
  background-color: #f8f1de; /* Light parchment color */
  border: 6px double #b98d63; /* Double border for scroll edge */
  border-radius: 20px; /* Rounded corners for scroll effect */
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.5), 0px 0px 0px 6px #b98d63 inset; /* Deep shadow for a lifted effect */
  margin-top: 10px;
  padding: 20px;
  font-weight: 500;
  overflow: hidden;
  /* font-family: 'Papyrus', 'Cairo', serif; Ancient-style font */
  background-image: url('https://www.transparenttextures.com/patterns/paper-fibers.png'); /* Background texture to mimic parchment */
  background-size: cover;
  animation: curlScroll 1.2s cubic-bezier(0.68, -0.55, 0.27, 1.55); /* Curling scroll animation */
  transform-origin: top right;
  opacity: 0.95; /* Slight transparency for aged look */
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.2)); /* Adds depth and realism */
}

/* Option styles */
.message-option {
  cursor: pointer;
  padding: 14px;
  border-bottom: 1px dashed #b98d63; /* Dashed border for an aged effect */
  transition: background-color 0.3s ease, transform 0.3s ease, color 0.3s ease; /* Smooth transitions for hover */
  color: #4a2c15; /* Dark brown color for text */
}

.message-option:last-child {
  border-bottom: none; /* Remove border from last option */
}

.message-option:hover {
  background-color: #e6d9b8; /* Darker parchment color on hover */
  transform: scale(1.1) rotateZ(-2deg); /* Rotate slightly for an interactive effect */
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3); /* Subtle text shadow for depth */
  color: #3b2311; /* Darker text color on hover */
}

/* Scroll open animation */
@keyframes curlScroll {
  0% {
    transform: rotateX(-90deg) scaleY(0.1);
    opacity: 0;
    filter: blur(5px);
  }
  50% {
    transform: rotateX(10deg) scaleY(1.2);
    opacity: 0.7;
    filter: blur(2px);
  }
  100% {
    transform: rotateX(0) scaleY(1);
    opacity: 1;
    filter: blur(0);
  }
}

/* Hover animation for parchment-like curl */
@keyframes parchmentHover {
  0% {
    transform: rotateZ(0);
  }
  50% {
    transform: rotateZ(-2deg);
  }
  100% {
    transform: rotateZ(0);
  }
}

/* Extra styling for a burnt edges effect */
.message-options::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('https://www.transparenttextures.com/patterns/crisp-paper.png'); /* Burnt edge texture */
  mix-blend-mode: multiply; /* Blend with the background to create a burnt look */
  border-radius: 20px;
  pointer-events: none; /* Makes sure this layer doesn't interfere with user actions */
}


.ticket-container {
  position: relative;
   /* Adjust the width as needed */
  height: 450px; /* Adjust the height as needed */
  background-color: #f0f0f0; /* Background color of the container */
   /* Optional: border for the ticket */
   border-radius: 10px;

   @media (max-width:480px) {
      height: 200px;
   }
}

.ticket-container::before,
.ticket-container::after {
  content: '';
  position: absolute;
  width: 30px; /* Diameter of the circle */
  height: 30px; /* Diameter of the circle */
  background-color: #ffffff; /* Match background color to blend with the container */
  border-radius: 50%;
  /* Optional: border for the circle to match the ticket border */
}

.ticket-container::before {
  left: -15px; /* Half of the circle's width to center it on the edge */
  top: 50%;
  transform: translateY(-50%);
}

.ticket-container::after {
  right: -15px; /* Half of the circle's width to center it on the edge */
  top: 50%;
  transform: translateY(-50%);
}

.ticket-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the entire container */
  border-radius: inherit; /* Ensures the image matches the ticket's rounded edges if any */
}


.animate {
  animation: fadeInUp 1s ease-out;
}

.main-banner, .offers, .featuring, .popular-puja, .blog-section, .product-section, .why-choose-us {
  /* opacity: 0.4; Start hidden */
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.animate {
  opacity: 1; /* Fade in */
}

.whatsapp-button img {
  width: 50px;
  height: 50px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader {
  display: inline-block;
  width: 64px;
  height: 64px;
}








/* Loader Styles */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white; /* Adjust according to your theme */
  position: fixed;
  width: 100%;
  z-index: 1000;
  top: 0;
  left: 0;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 80px;
  height: 80px;
}

.loader::before, .loader::after {
  content: "";
  position: absolute;
  border: 4px solid #d7910f; /* Match with your theme color */
  border-radius: 50%;
  animation: rotate 1.5s linear infinite, bounce 1.5s ease-in-out infinite;
}

.loader::before {
  width: 100%;
  height: 100%;
}

.loader::after {
  width: 70%;
  height: 70%;
  border-color: #ff9800; /* Match with your theme color */
  animation-delay: -0.75s;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}



.whatsapp-button {
  position: fixed;
  bottom: 30px;           
  right: 30px;
  width: 60px;
  height: 60px;
  background-color: #25d366;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 900;
}

.whatsapp-button img {
  width: 35px;
  height: 35px;
}





body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #ffffff;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: underline;
}

/* Container */
.container {
  margin: 0 auto;
  padding: 0 30px;
}

/* Main Content Styles */
.main-content {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding: 10px 0;
}

.main-banner,
.offer {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.main-banner-image,
.offer-image {
  width: 98%;
  height: auto;
  display: block;

  border-radius: 10px;
  transition: transform 0.3s ease;
}

.main-banner-image:hover,
.offer-image:hover {
  transform: scale(1.05);
}

.banner-text,
.offer-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  background-color: rgb(0 0 0 / 67%);
  padding: 20px;
  border-radius: 10px;
}

.banner-text h2 {
  font-size: 32px;
  margin-bottom: 10px;
}

.banner-text p,
.offer-text h3,
.offer-text h2 {
  font-size: 18px;
}

.shop-now {
  padding: 10px 20px;
  background-color: #ce4c0c;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  /* margin-top: 10px; */
  transition: background-color 0.3s ease;
}

.banner-main-text{
  color: white;
}

.shop-now:hover {
  background-color: #d2900c;
}

/* Slick Carousel Customization */
.slick-dots {
  bottom: 10px;
}

.slick-prev,
.slick-next {
  z-index: 1;
  color: #f0820b;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .main-content {
    grid-template-columns: 1fr;
  }

  .offers {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .offer {
    flex: 1;
  }
}

@media (max-width: 480px) {

  .main-banner {
    height: 250px;
    /* Adjust the height as needed */
  }


  .main-banner-image{
    height: 100%;
    object-fit: cover;
    /* Ensure the image covers the whole area */
  }

  .offer {
    height: 200px;
    /* Adjust the height as needed */
  }

  .offer-image {
    height: 100%;
    object-fit: cover;
    /* Ensure the image covers the whole area */
  }



  .banner-text h2 {
    font-size: 20px;
  }

  .banner-text p,
  .offer-text h3,
  .offer-text h2 {
    font-size: 10px;
  }

  .banner-text,
  .offer-text {
    padding: 8px;
  }

  .shop-now {
    padding: 8px 16px;
    font-size: 8px;
  }

  .container {
    padding: 0 15px;
  }

  .offer-image {
    width: 150%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .main-content {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .banner-text h2 {
    font-size: 28px;
  }

  .banner-text p,
  .offer-text h3,
  .offer-text h2 {
    font-size: 12px;
  }

  .shop-now {
    padding: 10px 20px;
    font-size: 12px;
  }
}

@media (min-width: 1024px) {
  .main-content {
    grid-template-columns: 2fr 1fr;
  }

  .offers {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}