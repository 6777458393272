.testimonial-slider-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ec8123;
  text-align: center;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.testimonial-card {
  animation: fadeIn 1s ease-in-out;
}

.testimonial-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 15px;
  object-fit: cover;
}

.testimonial-text {
  font-family: Arial, sans-serif;
}

.testimonial-quote {
  font-size: 14px;
  color: white;
  font-family: "Edu AU VIC WA NT Hand";
  font-style: italic;
  margin-bottom: 10px;
}

.testimonial-author {
  font-weight: bold;
  margin-bottom: 5px;
}

.testimonial-role {
  font-size: 12px;
  color: #777;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
