/* Date Picker Section Styling */
.mb4TR {
  margin-bottom: 1.5rem;
}

.blockLabelTR {
  display: block;
  font-size: 1.1rem;
  color: #333;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.datePickerTR {
  width: 100%;
  padding: 0.8rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease;
}

.datePickerTR:focus {
  border-color: #00bfa5;
  outline: none;
  box-shadow: 0 0 3px rgba(0, 191, 165, 0.5);
}

.bookButtonTR {
  background-color: #00bfa5;
  color: white;
  padding: 0.8rem 1.5rem;
  font-size: 1.1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 1.5rem;
}

.bookButtonTR:hover {
  background-color: #00897b;
}

/* Responsive Date Picker */
@media (max-width: 768px) {
  .datePickerTR {
    font-size: 0.9rem;
    padding: 0.7rem;
  }
}


/* Container and Grid */
.containerTR {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0.5rem;
  font-family: 'Arial', sans-serif;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.contentTR {
  display: flex;
  flex-direction: column;
  flex: 2;
  gap: 2rem;
  padding: 1.5rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.imageContainerTR {
  flex: 1;
  overflow: hidden;
  border-radius: 0.5rem;
}

.pujaImageTR {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 0.5rem;
  transition: transform 0.3s ease;
}

.pujaImageTR:hover {
  transform: scale(1.05);
}

.detailsTR {
  flex: 1;
}

/* Typography */
.titleTR {
  font-size: 2.5rem;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 1rem;
}

.subtitleTR {
  font-size: 1.5rem;
  font-weight: 600;
  color: #34495e;
  margin-bottom: 0.75rem;
}

.subsubtitleTR {
  font-size: 1.25rem;
  font-weight: 500;
  color: #34495e;
  margin-bottom: 0.5rem;
}

.mb4TR {
  margin-bottom: 2rem;
  margin-top: 3rem;
}

.mb2TR {
  margin-bottom: 1rem;
}

.listTR {
  list-style-type: disc;
  padding-left: 1.5rem;
  margin-bottom: 1.5rem;
  color: #7f8c8d;
}

/* Booking Section */
.bookingTR {
  flex: 1;
  border: 1px solid #e74c3c;
  padding: 1.5rem;
  background-color: #fdfbfb;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.priceTR {
  font-size: 1.5rem;
  font-weight: bold;
  color: #c0392b;
  margin-bottom: 1rem;
}

.featureTR {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  color: #27ae60;
}

.checkmarkTR {
  margin-right: 0.5rem;
}

.refundPolicyTR {
  color: #e74c3c;
  margin-bottom: 1.5rem;
}

.blockLabelTR {
  display: block;
  font-weight: 600;
  margin-bottom: 0.75rem;
  color: #2c3e50;
}

.buttonGroupTR {
  display: flex;
  gap: 1rem;
}

.buttonTR {
  border: 1px solid #34495e;
  padding: 1rem 2rem;
  border-radius: 0.25rem;
  background-color: #ecf0f1;
  color: #34495e;
  cursor: pointer;
  transition: all 0.3s ease;
}

.buttonTR.selected {
  background-color: #34495e;
  color: #ffffff;
}

.bookButtonTR {
  background-color: #e74c3c;
  color: #ffffff;
  padding: 1rem 2rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  width: 100%;
  font-size: 1.25rem;
  transition: background-color 0.3s ease;
}

.bookButtonTR:hover {
  background-color: #c0392b;
}

/* Package Details */
.packageDetailsTR {
  padding: 1.5rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 1.5rem;
  transition: all 0.3s ease;
}

.priceDetailsTR {
  font-size: 1.5rem;
  font-weight: bold;
  color: #c0392b;
  margin-bottom: 1rem;
}

.packageDetailsTR ul {
  padding-left: 1rem;
  list-style-type: disc;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeIn 0.5s ease;
}

/* Responsive Design */
@media (max-width: 768px) {
  .containerTR {
    flex-direction: column;
  }

  .imageContainerTR, .bookingTR {
    padding-left: 1rem;
    padding-right: 1rem;
    width: calc(100% - 2rem);
    margin: 0 auto;
  }

  .titleTR {
    font-size: 2rem;
  }

  .subtitleTR {
    font-size: 1.25rem;
  }

  .subsubtitleTR {
    font-size: 1rem;
  }

  .mb4TR {
    margin-bottom: 1.5rem;
    margin-top: 2rem;
  }

  .mb2TR {
    margin-bottom: 0.5rem;
  }

  .listTR {
    margin-bottom: 1rem;
  }

  .bookingTR {
    margin-top: 1.5rem;
    padding: 1rem;
  }

  .priceTR {
    font-size: 1.25rem;
  }

  .featureTR {
    margin-bottom: 0.5rem;
  }

  .refundPolicyTR {
    margin-bottom: 1rem;
  }

  .blockLabelTR {
    margin-bottom: 0.5rem;
  }

  .buttonTR {
    padding: 0.75rem 1.5rem;
  }

  .bookButtonTR {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
  }

  .packageDetailsTR {
    padding: 1rem;
  }

  .priceDetailsTR {
    font-size: 1.25rem;
  }
}
