.header-root {
  width: 100%;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  position: sticky;
  top: 0;
  z-index: 900;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.app-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 70px;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.logo {
  display: flex;
  font-size: 1.8rem;
  font-weight: bold;
  cursor: pointer;
  color: #d7910f;
  transition: color 0.3s;
  align-items: center;
  gap: 1rem;
}

.logo-title {
  @media (max-width: 768px) {
    display: none;
  }
}

.logo img {
  height: 50px;
  width: 50px;
}

.logo:hover {
  color: #ffb74d;
}

.nav-items {
  display: flex;
  align-items: center;
}

.nav-item {
  color: #000000;
  margin: 0 20px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: color 0.3s, transform 0.3s;
}

.nav-item:hover {
  color: #ff9800;
  transform: translateY(-2px);
}

.nav-item.active {
  color: orange;
  font-weight: bold;
}

.drawer-item.active {
  color: orange;
  font-weight: bold;
}


.cart-icon-wrapper {
  position: relative;
  display: inline-block; /* Ensure this wrapper is positioned relatively */
  z-index: 1000; /* Bring it to the front if needed */
}

.cart-item-count {
  position: absolute;
  top: -0.8vh; /* Adjust this to place the badge higher */
  right: 1vh; /* Adjust this to place the badge more to the right */
  background-color: #f1f3f5;
  color: black;
  border-radius: 50%;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  height: 20px;
  border: 1px solid #e0e0e0;
  z-index: 1001; /* Ensure the item count is above other elements */
}

.cart-item-count {
  display: flex !important;
}



.search {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 20px;
  border: 2px solid #000000;
  border-radius: 10px;
}

.search-icon {
  position: absolute;
  left: 10px;
  cursor: pointer;
  color: #000000;
}

.input-base {
  padding: 10px 10px 10px 40px;
  border: none;
  border-radius: 30px;
  outline: none;
  width: 250px;
  transition: width 0.4s ease;
  display: block;
}

.icon-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1.4rem;
  color: #000000;
  margin-right: 20px;
}

.mobile-menu-button {
  display: none;
  cursor: pointer;
  font-size: 1.8rem;
  color: #000000; /* Change color to black */
}

.burger-menu {
  width: 2vh;
  height: 2vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1000;
}

.burger-menu .bar {
  z-index: 1000;
  width: 100%;
  height: 3px; /* Increase height for thicker lines */
  background-color: #000000;
  transition: all 0.3s ease;
}

.burger-menu.open .bar:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.burger-menu.open .bar:nth-child(2) {
  opacity: 0;
}

.burger-menu.open .bar:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}


.drawer {
  position: fixed;
  top: 0;
  right: -100%;
  width: 300px;
  height: 100%;
  background: linear-gradient(to bottom, #f0e68c, #e6c68d); /* Gradient for aged parchment look */
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2), inset 0 0 10px rgba(0, 0, 0, 0.1); /* Shadow for depth */
  border-left: 5px solid #8b4513; /* Dark brown border to mimic scroll edge */
  border-right: 5px solid #8b4513; /* Dark brown border to mimic scroll edge */
  border-radius: 20px 0 0 20px; /* Rounded corners for the scroll look */
  transition: right 0.3s ease;
  z-index: 1000;
  padding: 20px;
  overflow-y: auto; /* Enable scrolling if content exceeds height */
  background-color: #f5deb3; /* Base color for parchment paper */
  background-image: radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.5), rgba(245, 222, 179, 0.5), rgba(210, 180, 140, 0.5)); /* Subtle texture for an aged look */
}

.drawer.open {
  right: 0;
}

.drawer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.drawer-item {
  padding: 15px 0;
  border-bottom: 1px dashed #d2b48c; /* Dashed border for a more rustic look */
  cursor: pointer;
  font-size: 1.2rem;
  transition: color 0.3s, transform 0.3s;
  width: 100%;
  text-align: center;
  color: #4b2e04; /* Darker brown text color */
  font-family: 'Caveat', cursive; /* Handwritten font for more authenticity */
}

.drawer-item:hover {
  color: #8b4513; /* Darker hover color */
  transform: translateX(5px);
}

.drawer-item:first-child {
  border-top: 1px dashed #d2b48c; /* Add top border to the first item */
}


/* .drawer {
  position: fixed;
  top: 0;
  right: -100%;
  width: 300px;
  height: 100%;
  background-color: #ffffff;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2);
  transition: right 0.3s ease;
  z-index: 1000;
  padding: 20px;
}

.drawer.open {
  right: 0;
}

.drawer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.drawer-item {
  padding: 15px 0;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
  font-size: 1.2rem;
  transition: color 0.3s, transform 0.3s;
  width: 100%;
  text-align: center;
}

.drawer-item:hover {
  color: #ff9800;
  transform: translateX(5px);
} */

.search-results {
  background: linear-gradient(to bottom, #f0e68c, #e6c68d); /* Gradient for aged parchment look */
  border: 3px solid #8b4513; /* Dark brown border for scroll edge */
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2), inset 0 0 10px rgba(0, 0, 0, 0.1); /* Shadow for depth and inset shadow for a pressed look */
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1000;
  max-height: 300px;
  overflow-y: auto;
  border-radius: 20px; /* Rounded corners for scroll look */
  padding: 15px 10px; /* Added padding for a balanced layout */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  /* font-family: 'Caveat', cursive; Handwritten font for more authenticity */
  /* background-image: url('data:image/svg+xml;base64,[base64 encoded svg for torn edges]'); Optional: Add an image for torn edges */
  background-repeat: no-repeat;
  background-size: cover;
}

.search-results::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari */
}

.search-result-item {
  display: flex;
  align-items: center;
  padding: 12px 15px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  margin: 8px 10px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.2); /* Transparent layer for a more textured look */
  border: 1px dashed #d2b48c; /* Dashed border for a more rustic look */
  position: relative;
}

.search-result-item:before {
  content: '';
  position: absolute;
  top: 0;
  left: -5px;
  right: -5px;
  bottom: 0;
  border-left: 5px solid rgba(255, 255, 255, 0.6); /* Torn effect on the left */
  border-right: 5px solid rgba(255, 255, 255, 0.6); /* Torn effect on the right */
  pointer-events: none;
}

.search-result-item:hover {
  background-color: rgba(245, 222, 179, 0.8); /* Lightened parchment color on hover */
  transform: translateY(-2px) scale(1.02); /* Lift effect with slight enlargement */
  border: 1px solid #8b4513; /* Darker border on hover for emphasis */
}

.search-result-item img {
  width: 50px;
  height: 50px;
  margin-right: 15px;
  border-radius: 50%;
  border: 2px solid #d2b48c; /* Image border to match the scroll theme */
}

.search-result-item .product-name {
  font-size: 16px;
  font-weight: bold;
  color: #4b2e04; /* Darker brown for text */
  font-family: 'Caveat', cursive; /* Handwritten style for text */
}

@media (max-width: 768px) {
  .search-results {
    width: 125%;
  }
}


@media (max-width: 768px) {
  .logo {
    font-size: 1.5rem;
    margin-right: 20px;
  }

  .nav-items {
    display: none;
  }

  .mobile-menu-button {
    display: flex;
    align-items: center;
  }

  .input-base {
    display: block;
    width: 100%;
  }

  .search.open .input-base {
    display: block;
    width: 100%;
  }

  .search-results {
    width: 125%;
  }
}
