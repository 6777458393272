.admin-panel-container {
    display: flex;
}

.content-container {
    flex-grow: 1;
    padding: 20px;
}

.page-title {
    font-size: 24px;
    margin-bottom: 20px;
}

.filter-container {
    margin-bottom: 20px;
}

.filter-container input {
    margin-right: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.table-container {
    overflow-x: auto;
}

.service-table {
    width: 100%;
    border-collapse: collapse;
}

.service-table th,
.service-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
}

.service-table th {
    background-color: #f2f2f2;
}

@media (max-width: 768px) {
    .table-container {
        overflow-x: auto;
    }

    .filter-container {
        display: flex;
        flex-direction: column;
    }

    .filter-container input {
        margin-bottom: 10px;
    }
}
