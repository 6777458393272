.blog-section {
  padding: 10px;
  background-color: transparent;
  text-align: center;
  margin-top: 3rem;
 
  @media (max-width:480px) {
    margin-top: 1.2rem;
    
  }
}

.blog-section-title {
  font-size: 2em;
  margin-bottom: 20px;
  color: #333;

  @media (max-width:480px) {
    font-size: 1.5em;
  }
}

.blog-posts-container {
  position: relative;
  overflow: hidden; /* Hide the scrollbar */
}

.blog-posts {
  display: flex;
  overflow-x: auto;
  gap: 20px;
  padding-top: 15px;
  padding-bottom: 10px;
  scrollbar-width: none; /* For Firefox */
  margin-bottom: 1rem;
  scroll-snap-type: x mandatory; /* Ensure cards snap into place */
  @media (max-width:480px) {
    padding-top: 5px;
  }
}

.blog-posts::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.scroll-button.left {
  left: 10px;
}

.scroll-button.right {
  right: 10px;
}

.scroll-button:focus {
  outline: none;
}

.scroll-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.blog-post-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.2s;
  flex: 0 0 250px; /* Ensure cards have a fixed width */
  height: 330px; /* Fixed height */
  scroll-snap-align: start; /* Snap each card to the start of the scroll container */

  @media (max-width:480px) {
    flex: 0 0 180px; /* Makes sure cards are smaller on mobile screens */
    height: 280px;
  }
}

.blog-post-card:hover {
  transform: translateY(-10px);
}

.blog-post-image {
  width: 100%;
  height: 200px; /* Adjusted height */
  object-fit: cover;
}

.blog-post-content {
  padding: 15px;
  text-align: left;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  height: calc(100% - 150px); /* Adjust content height based on image height */
}

.blog-post-title {
  font-size: 1.2em;
  margin: 10px 0;
  color: #f0820b;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blog-post-date {
  font-size: 0.9em;
  color: #666;
}

@media (max-width: 768px) {
  .blog-post-title {
    font-size: 1em;
  }

  .blog-post-date {
    font-size: 0.8em;
  }
}

@media (max-width: 480px) {
  .blog-posts {
    gap: 10px;
  }

  

  .blog-post-image {
    height: 180px; /* Reduced height for mobile */
  }

  .blog-post-title {
    font-size: 0.9em;
  }

  .blog-post-date {
    font-size: 0.7em;
  }

  .scroll-button {
    display: none;
  }
}
