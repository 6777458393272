.RT-about-section {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    background-color: #ffffff; /* Light background color */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    flex-wrap: wrap; /* Allow wrapping */
  }
  
  .RT-about-text {
    flex: 1;
    margin-right: 2rem;
  }
  
  .RT-about-heading {
    font-size: 2rem;
    color: #d32f2f; /* Deep red color to match puja theme */
    margin-bottom: 1rem;
  }
  
  .RT-about-paragraph {
    font-size: 1rem;
    color: #333; /* Dark text color for readability */
    line-height: 1.6;
  }
  
  .RT-about-animation {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .RT-about-animation > div {
    width: 100%;
    max-width: 400px; /* Max width for the animation */
  }
  
  @media (max-width: 768px) {
    .RT-about-section {
      padding: 1rem;
    }
  
    .RT-about-text {
      margin-right: 0;
      margin-bottom: 1.5rem; /* Space between text and animation */
      text-align: center; /* Center text on smaller screens */
    }
  
    .RT-about-heading {
      font-size: 1.5rem;
    }
  
    .RT-about-paragraph {
      font-size: 0.9rem;
    }
  
    .RT-about-animation {
      width: 100%;
      max-width: 300px; /* Adjust max width for smaller screens */
    }
  
    .RT-about-animation > div {
      max-width: 100%; /* Ensure animation takes full width */
    }
  }
  
  @media (max-width: 480px) {
    .RT-about-heading {
      font-size: 1.2rem;
    }
  
    .RT-about-paragraph {
      font-size: 0.8rem;
    }
  }
  