/* PoojaCategoryCard.css */
.pooja-category-card {
    border: 1px solid #ccc;
    padding: 16px;
    margin: 16px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .pooja-category-card h3 {
    margin-top: 0;
  }
  
  .pooja-category-card p {
    margin: 8px 0;
  }
  