/* AdminOrders.css */

.admin-panel-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 100vh;
}

.content-container {
    margin-left: 20%;
    overflow-y: auto;
    width: 78%;
    flex: 1;
    padding: 20px;
    background-color: #f9f9f9;
}

.page-title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
}

.filter-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}

.filter-container input[type="text"] {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    flex: 1;
    min-width: 200px;
}

.filter-container button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.filter-container button:hover {
    background-color: #0056b3;
}

.table-container {
    overflow-x: auto;
}

.user-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.user-table th, .user-table td {
    padding: 12px;
    text-align: left;
    border: 1px solid #ddd;
    white-space: nowrap;
}

.user-table th {
    background-color: #f1f1f1;
    font-weight: bold;
}

.user-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.user-table tr:hover {
    background-color: #f1f1f1;
}

@media (max-width: 768px) {
    .filter-container {
        flex-direction: column;
        gap: 15px;
    }

    .filter-container input[type="text"] {
        width: 100%;
    }

    .filter-container button {
        width: 100%;
    }
}
