body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
}

.ord-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.ord-header {
    text-align: center;
    color: #333;
}

.ord-subheader {
    text-align: center;
    color: #666;
}

.ord-details {
    margin-top: 20px;
}

.ord-info p {
    margin: 5px 0;
}

.ord-pay-method {
    margin: 20px 0;
    text-align: center;
    font-weight: bold;
    color: #333;
}

.ord-section-header {
    margin-top: 30px;
    color: #333;
    border-bottom: 2px solid #eee;
    padding-bottom: 10px;
}

.ord-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.ord-table th, .ord-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
}

.ord-subtotal, .ord-shipping, .ord-total {
    margin: 10px 0;
    font-weight: bold;
}

.ord-address {
    background-color: #f9f9f9;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #ddd;
}
