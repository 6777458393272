.RT-banner-container {
    width: 100%;
    /* max-width: 1200px; */
    margin: auto;
    position: relative;
    overflow: hidden;
  }
  
  .RT-banner-slider {
    display: flex;
    position: relative;
    width: 100%;
  }
  
  .RT-banner-slides {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 100%;
  }
  
  .RT-banner-slide {
    min-width: 100%;
    height: 300px; /* Adjust height as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .RT-banner-slide img {
    width: 100%;
    height: 500px; /* Ensure the image fills the container's height */
    object-fit: cover; /* Ensure the whole image is visible */
  }
  
  .RT-banner-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    cursor: pointer;
    padding: 10px;
    font-size: 18px;
    z-index: 10;
  }
  
  .RT-banner-prev {
    left: 10px;
  }
  
  .RT-banner-next {
    right: 10px;
  }
  
  .RT-banner-dots {
    text-align: center;
    position: absolute;
    bottom: 15px;
    width: 100%;
  }
  
  .RT-banner-dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
  }
  
  .RT-banner-dot.active,
  .RT-banner-dot:hover {
    background: rgba(0, 0, 0, 0.8);
  }
  