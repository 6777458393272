/* General Styles */
.WY-why-choose-us {
    text-align: center;
    padding: 50px 20px;
    background-color:transparent;
  }
  
  .WY-section-title {
    font-size: 2rem;
    margin-bottom: 40px;
    position: relative;
    color: #333;
  }
  
  .WY-section-title .WY-highlight {
    display: block;
    color: #ff4757;
    font-size: 1rem;
    margin-bottom: 5px;
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .WY-features {
    display: flex;
    flex-wrap: wrap;
    gap: 4.5rem;
    justify-content: center;
  }
  
  .WY-feature-item {
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
    border-radius: 12px;
    padding: 30px 20px;
    background-color: #fff;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
    width: 100%;
    max-width: 250px;
  }
  
  .WY-feature-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  }
  
  .WY-feature-item img {
    width: 60px;
    height: 60px;
    margin-bottom: 15px;
  }
  
  .WY-feature-item h3 {
    font-size: 1.4rem;
    margin-bottom: 10px;
    color: #333;

    @media (max-width:480px) {
        font-size: 1rem;
    }
  }
  
  .WY-feature-item p {
    font-size: 1rem;
    color: #777;

    @media (max-width:480px) {
        font-size: 0.8rem;
    }
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .WY-feature-item {
      flex: 1 1 calc(33.33% - 30px);
      max-width: calc(33.33% - 30px);
    }
  }
  
  @media (max-width: 768px) {
    .WY-feature-item {
      flex: 1 1 calc(50% - 30px);
      max-width: calc(50%);
    }
  }
  
  @media (max-width: 480px) {
    .WY-feature-item {
      flex: 1 1 calc(50% - 20px);
      /* max-width: calc(50% - 52px); */
    }

    .WY-features {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        justify-content: start;
    }
  }
  