/* Container */
.catering-services-container {
    max-width: 600px;
    margin: 40px auto;
    padding: 20px;
    background: linear-gradient(145deg, #ffffff, #f0f0f0);
    border-radius: 15px;
    box-shadow: 5px 5px 10px #d1d1d1, -5px -5px 10px #ffffff;
  }
  
  /* Title */
  .catering-services-title {
    text-align: center;
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
    font-family: 'Arial', sans-serif;
    position: relative;
  }
  
  .catering-services-title::after {
    content: '';
    width: 60px;
    height: 3px;
    background-color: #ff9800;
    display: block;
    margin: 10px auto;
  }
  
  /* Form */
  .catering-services-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  /* Form Group */
  .catering-services-form-group {
    display: flex;
    flex-direction: column;
  }
  
  .catering-services-form-label {
    font-weight: bold;
    margin-bottom: 5px;
    color: #555;
  }
  
  /* Input Fields */
  .catering-services-form-input {
    padding: 12px;
    border: 2px solid #ddd;
    border-radius: 10px;
    font-size: 16px;
    transition: all 0.3s ease;
    box-shadow: inset 3px 3px 6px #d1d1d1, inset -3px -3px 6px #ffffff;
  }
  
  .catering-services-form-input:focus {
    border-color: #ff9800;
    outline: none;
    box-shadow: 0 0 0 4px rgba(255, 152, 0, 0.2);
  }
  
  /* Submit Button */
  .catering-services-submit-button {
    padding: 15px;
    background-color: #ff9800;
    color: #fff;
    border: none;
    border-radius: 10px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 3px 3px 6px #d1d1d1, -3px -3px 6px #ffffff;
  }
  
  .catering-services-submit-button:hover {
    background-color: #e68900;
    transform: translateY(-2px);
  }
  
  @media (max-width: 768px) {
    .catering-services-container {
      max-width: 80%;
    }
  
    .catering-services-title {
      font-size: 1.5rem;
    }
  
    .catering-services-form-input {
      font-size: 14px;
    }
  
    .catering-services-submit-button {
      font-size: 16px;
    }
  }
  