/* PopularPuja.css */

.popular-puja-container {
    padding: 20px;
    background-color:transparent;
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .popular-puja-title {
    font-size: 2em;
    margin-bottom: 20px;
    color: #333;
  }
  
  .popular-puja-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .puja-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.2s;
    width: 280px;
  }
  
  .puja-card:hover {
    transform: translateY(-10px);
  }
  
  .puja-card img {
    width: 100%;
    height: 15rem; /* Fixed height for all images */
    object-fit: cover; /* Ensure the image covers the space */
  }
  
  .puja-card-content {
    padding: 15px;
  }
  
  .puja-card-title {
    font-size: 1.2em;
    margin: 10px 0;
    color: #f0820b;
  }
  
  .puja-card-price {
    font-size: 1em;
    color: #333;
  }
  
  .puja-card-price span {
    color: #e60023;
  }
  
  .view-more-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background-color: #f0820b;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .view-more-button:hover {
    background-color: #5f2902;
  }
  
  @media (max-width: 768px) {
    .puja-card {
      width: 50%;
      margin-top: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    

    .popular-puja-container {
        padding: 0px;
        
      }

    .popular-puja-grid {
      gap: 8px;
    }
  
    .puja-card {
      width: calc(50% - 4px); /* Ensure two cards per row with a gap */
    }
  
    .puja-card img {
      height: 10rem; /* Reduce image size for mobile screens */
    }
  
    .puja-card-title {
      font-size: 14px; /* Reduce font size for mobile screens */
    }
  
    .puja-card-price {
      font-size: 12px /* Reduce font size for mobile screens */
    }
  }
  