.footer {
    background-color: #faece0;
    color: #4d2500;
    padding: 40px 0;
    
    bottom: 0;
  }
  
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    bottom: 0;
  }
  
  .footer-section {
    flex: 1;
    min-width: 250px;
    margin: 20px 0;
  }
  
  .footer-section h2 {
    font-size: 22px;
    margin-bottom: 20px;
    color: #792400;
  }
  
  .footer-section p,
  .footer-section ul {
    font-size: 16px;
    line-height: 1.6;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 10px;
  }
  
  .footer-section ul li a {
    color: #4d1b00;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .footer-section ul li a:hover {
    color: #d81b60;
  }
  
  .footer-social {
    text-align: center;
    margin-top: 20px;
  }
  
  .footer-social a {
    color: #4d2500;
    margin: 0 10px;
    font-size: 24px;
    transition: color 0.3s;
  }
  
  .footer-social a:hover {
    color: #d81b60;
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 20px;
    border-top: 1px solid #4d1c00;
    padding-top: 10px;
    font-size: 14px;
  }
  
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-section {
      text-align: center;
    }
  }
  
  @media (max-width: 480px) {
    .footer {
      padding: 20px 0;
    }
  
    .footer-section h2 {
      font-size: 18px;
    }
  
    .footer-section p,
    .footer-section ul {
      font-size: 14px;
    }
  }
  