/* Container */
.ContactPage-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    max-width: 1200px;
    margin: 40px auto;
    background: linear-gradient(145deg, #ffffff, #f0f0f0);
    border-radius: 15px;
    box-shadow: 5px 5px 10px #d1d1d1, -5px -5px 10px #ffffff;
  }
  
  /* About Us Section */
  .ContactPage-about-us {
    flex: 1;
    margin-right: 20px;
  }
  
  .ContactPage-about-us h1 {
    text-align: center;
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
    font-family: 'Arial', sans-serif;
  }
  
  .ContactPage-about-us p {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
  }
  
  /* Contact Form Section */
  .ContactPage-contact-form {
    flex: 1;
    margin-left: 20px;
  }
  
  .ContactPage-contact-form h1 {
    text-align: center;
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
    font-family: 'Arial', sans-serif;
  }
  
  .ContactPage-contact-form-details {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  /* Form Group */
  .ContactPage-form-group {
    display: flex;
    flex-direction: column;
  }
  
  .ContactPage-form-group label {
    font-weight: bold;
    margin-bottom: 5px;
    color: #555;
  }
  
  /* Input Fields */
  .ContactPage-form-group input,
  .ContactPage-form-group textarea {
    padding: 12px;
    border: 2px solid #ddd;
    border-radius: 10px;
    font-size: 16px;
    transition: all 0.3s ease;
    box-shadow: inset 3px 3px 6px #d1d1d1, inset -3px -3px 6px #ffffff;
  }
  
  .ContactPage-form-group input:focus,
  .ContactPage-form-group textarea:focus {
    border-color: #ff9800;
    outline: none;
    box-shadow: 0 0 0 4px rgba(255, 152, 0, 0.2);
  }
  
  /* Submit Button */
  .ContactPage-submit-button {
    padding: 15px;
    background-color: #ff9800;
    color: #fff;
    border: none;
    border-radius: 10px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 3px 3px 6px #d1d1d1, -3px -3px 6px #ffffff;
  }
  
  .ContactPage-submit-button:hover {
    background-color: #e68900;
    transform: translateY(-2px);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .ContactPage-container {
      flex-direction: column;
      align-items: center;
      max-width: 82%;
    }
  
    .ContactPage-about-us,
    .ContactPage-contact-form {
      margin: 0;
      width: 100%;
      max-width: 80%;
    }
  
    .ContactPage-about-us h1,
    .ContactPage-contact-form h1 {
      font-size: 1.5rem;
    }
  
    .ContactPage-form-group input,
    .ContactPage-form-group textarea {
      font-size: 14px;
    }
  
    .ContactPage-submit-button {
      font-size: 16px;
    }
  }
  