.carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;


  @media (max-width:480px) {
    height: 250px;
  }
}

.carousel-inner {
  display: flex;
  transition: transform 0.5s ease;
}

.carousel-item {
  min-width: 100%;
  transition: opacity 0.5s ease;
  position: relative;
}

.carousel-item.active {
  opacity: 1;
}

.carousel-item:not(.active) {
  opacity: 0;
}

.carousel-image {
  width: 100%;
  
  height: auto;
  display: block;
  border-radius: 10px;
  
  object-fit: cover; /* Ensures the image covers the entire area */

  @media (max-width:480px) {
    height: 250px;
  }
}

.carousel-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.67);
  padding: 20px;
  border-radius: 10px;
}

.carousel-text h2 {
  font-size: 32px;
  margin-bottom: 10px;
}

.carousel-text p {
  font-size: 18px;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 2rem;
  color: #f0820b;
  cursor: pointer;
  z-index: 1;
}

.carousel-button.prev {
  left: 10px;
}

.carousel-button.next {
  right: 10px;
}

.shop-now {
  padding: 10px 20px;
  background-color: #ce4c0c;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  transition: background-color 0.3s ease;
}

.shop-now:hover {
  background-color: #d2900c;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .carousel-text h2 {
    font-size: 24px;
  }

  .carousel-text p {
    font-size: 16px;
  }

  .carousel-button {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .carousel-text h2 {
    font-size: 16px;
  }

  .carousel-text p {
    font-size: 10px;
  }

  .carousel-button {
    font-size: 1rem;
  }
}
