.cart-hover {
  position: fixed;
  bottom: 4rem;
  left: 6rem;
  width: 63%;
  height: 4rem;
  background: rgb(12, 131, 31);
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  font-size: 1.2rem;
  border-radius: 34px;
  font-weight: bold;
  z-index: 1000;
}

.view-button1, .view-button2 {
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #036212;
  border-radius: 50%;
}
