.similar-product {
  padding: 0 8%;
  margin-top: 5%;
}

.rt-product-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 20px;
  background-color: #fff;
  max-width: 1200px;
  margin: auto;
  
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.rt-product-gallery {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rt-product-main-image {
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
}

.rt-product-main-image img {
  width: 100%;
  height: 500px;
  object-fit: cover;
  border-radius: 0px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width:480px) {
    height: 350px;
  }
}

.rt-product-thumbnails {
  display: flex;
  width: 100%;
  
  overflow-x: scroll;
  gap: 10px;
  justify-content: center;
}::-webkit-scrollbar {
  display: none;
}

.rt-product-thumbnails img {
  width: 70px;
  height: 70px;
  cursor: pointer;
  border-radius: 5px;
  transition: transform 0.3s ease, border 0.3s ease;
  object-fit: cover;
  border: 2px solid transparent;
}

.rt-product-thumbnails img:hover {
  transform: scale(1.1);
}

.rt-active {
  border: 2px solid #ff8c00;
}

.rt-product-info {
  flex: 1;
  background: #ffffff;
  padding: 20px;
  /* border-radius: 10px; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.rt-product-info h1 {
  font-size: 2.2em;
  color: #333;
  margin-bottom: 20px;
}

.rt-product-price {
  font-size: 1.8em;
  color: #ff8c00;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.rt-discounted-price {
  color: #ff8c00;
  margin-right: 10px;
}

.rt-original-price {
  color: #999;
  text-decoration: line-through;
}

.rt-product-description {
  margin-bottom: 20px;
  font-size: 1.2em;
  color: #666;
}



.rt-product-quantity {
  margin-top: 20px;
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-bottom: 20px;
}

.rt-quantity-selector {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.rt-quantity-selector button {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  padding: 5px 10px;
  cursor: pointer;
}

.rt-quantity-selector button:focus {
  outline: none;
}

.rt-quantity-selector input {
  width: 50px;
  text-align: center;
  border: 1px solid #ccc;
  margin: 0 5px;
  padding: 4px;
}






/* .rt-quantity-selector {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  background-color: #f0f0f0;
  border-radius: 30px;
  padding: 5px;
  width: fit-content;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.rt-quantity-selector button {
  background-color: transparent;
  color: #ff8c00;
  border: none;
  font-size: 1.8em;
  cursor: pointer;
  padding: 10px;
  transition: color 0.3s ease, transform 0.3s ease;
}

.rt-quantity-selector button:hover {
  color: #e07b00;
  transform: scale(1.1);
}

.rt-quantity-selector span {
  padding: 0 15px;
  font-size: 1.5em;
  color: #333;
  min-width: 40px;
  text-align: center;
}


@media (max-width: 768px) {
  .rt-quantity-selector {
    width: 100%;
    justify-content: space-between;
    padding: 5px 10px;
  }

  .rt-quantity-selector button {
    font-size: 1.5em;
    padding: 5px;
  }

  .rt-quantity-selector span {
    font-size: 1.2em;
    padding: 0 10px;
  }
}

@media (max-width: 480px) {
  .rt-quantity-selector {
    padding: 5px;
  }

  .rt-quantity-selector button {
    font-size: 1.2em;
    padding: 3px;
  }

  .rt-quantity-selector span {
    font-size: 1em;
    padding: 0 5px;
  }
} */


.rt-product-actions {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.rt-product-add-to-cart,
.rt-product-buy-now {
  flex: 1;
  padding: 15px;
  font-size: 1em;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.rt-product-add-to-cart {
  background-color: #ff8c00;
  color: white;
  border: none;
}

.rt-product-add-to-cart:hover {
  background-color: #e07b00;
}

.rt-product-buy-now {
  background-color: white;
  color: #ff8c00;
  border: 1px solid #ff8c00;
}

.rt-product-buy-now:hover {
  background-color: #ff8c00;
  color: white;
}

.rt-product-shipping-info {
  margin-bottom: 20px;
}

.rt-product-shipping-info ul {
  padding-left:   0px;
}

.rt-product-shipping-info li {
  list-style: none;
  font-size: 1.1em;
  color: #333;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.rt-icon {
  margin-right: 10px;
  color: #ff8c00;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .rt-product-shipping-info li {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .rt-product-shipping-info li {
    font-size: 0.9em;
  }

  .rt-icon {
    margin-right: 5px;
  }
}


.rt-product-share {
  display: flex;
  align-items: center;
  gap: 10px;
}

.rt-product-share span {
  font-size: 1.2em;
  color: #333;
}

.rt-share-icon {
  font-size: 1.5em;
  color: #555;
  cursor: pointer;
  transition: color 0.3s ease;
}

.rt-share-icon:hover {
  color: #ff8c00;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .rt-product-container {
    flex-direction: column;
  }

  .rt-product-gallery {
    width: 100%;
  }
  
  .rt-product-info {
    width: 90%;
    margin: auto;
  }

  .rt-product-main-image img {
    border-radius: 0px;
  }

  .rt-product-info h1 {
    font-size: 1.8em;
  }

  .rt-product-price {
    font-size: 1.5em;
  }

  .rt-product-description {
    font-size: 1em;
  }

  .rt-quantity-selector button,
  .rt-quantity-selector span {
    font-size: 1em;
  }

  .rt-product-actions .rt-product-add-to-cart,
  .rt-product-actions .rt-product-buy-now {
    font-size: 0.9em;
  }

  .rt-product-share span {
    font-size: 1em;
  }
}