.T-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    background-color: white;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
}

.T-title {
    margin-bottom: 1rem;
    font-family: "Edu AU VIC WA NT Hand", cursive;
    font-optical-sizing:auto;
    font-weight: 600;
    font-style: normal;
}

.T-name {
    font-size: 20px;
}
.gole1{
    cursor: pointer;
}
@media (max-width: 480px) {
    .T-name {
        font-size: 14px;
    }
}

.gole {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: auto; /* Enable horizontal scrolling */
    justify-content: center;
    gap: 4rem;
    width: 100%; /* Ensure it takes full width */

    @media (max-width:480px) {
        justify-content: flex-start;
    }
}

.gole::-webkit-scrollbar {
    display: none; /* Hide scrollbar in Safari and Chrome */
}

.gole {
    -ms-overflow-style: none;  /* Hide scrollbar in Internet Explorer 10+ */
    scrollbar-width: none; /* Hide scrollbar in Firefox */
}

@media (max-width: 480px) {
    .gole {
        gap: 3.6rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}

.gole1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 0 auto; /* Prevent items from shrinking */
}

.gola-image {
    height: 200px;
    width: 200px;
    border-radius: 50%;
    background: rgba(129, 123, 123, 0.301);
    margin-bottom: 10px;
}

@media (max-width: 480px) {
    .gola-image {
        height: 100px;
        width: 100px;
    }
}
