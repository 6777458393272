/* BlogDetailsPage.css */

.Blog-Details-container {
  max-width: 1300px;
  margin: 40px auto;
  padding: 20px;
  font-size: 1.2rem;
  font-family: 'Arial, sans-serif';
  color: #333;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.Blog-Details-container:hover {
  transform: scale(1.02);
}

.Blog-Details-image-container {
  width: 100%;
  height: 400px;
  overflow: hidden;
  border-radius: 15px 15px 0 0;
}

.Blog-Details-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  transition: transform 0.3s ease-in-out;
}

.Blog-Details-image:hover {
  transform: scale(1.05);
}

.Blog-Details-content {
  padding: 30px;
}

.Blog-Details-title {
  font-size: 2.5em;
  margin-bottom: 10px;
  color: #444;
  transition: color 0.3s ease;
}

.Blog-Details-title:hover {
  color: #000;
}

.Blog-Details-meta {
  font-size: 0.9em;
  color: #888;
  margin-bottom: 20px;
}

.Blog-Details-meta span {
  margin-right: 5px;
}

.Blog-Details-description {
  font-size: 1.2em;
  margin-bottom: 20px;
  color: #666;
}

.Blog-Details-text p {
  line-height: 1.8;
  margin-bottom: 20px;
  color: #555;
}

@media (max-width: 900px) {
  .Blog-Details-container {
    max-width: 90%;
    padding: 10px;
  }

  .Blog-Details-content {
    padding: 20px;
  }

  .Blog-Details-title {
    font-size: 1.5em;
  }

  .Blog-Details-description {
    font-size: 0.9em;
  }

  .Blog-Details-meta {
    font-size: 0.8em;
  }

  .Blog-Details-text p {
    font-size: 0.9em;
    margin-bottom: 15px;
  }
}
